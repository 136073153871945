import React, { Component, Fragment } from 'react';
// import {Helmet} from "react-helmet";
// import Safe from 'react-safe';
// import ReactGA from 'react-ga';

//language file
import { translate } from '../../i18n/translate';

// import { getContestState } from '../../util';
// import '../../styles/pages/_rules.scss';

const en_id = 'e6abf776-eefa-49da-a110-2a83a75c1149';
const fr_id = '203149eb-931d-499f-ac4a-5ff64e4920ac';

class Terms extends Component{

    componentDidMount(){
      const _id = this.props.lang==='fr-CA' ? fr_id : en_id;
      eval(`OneTrust.NoticeApi.LoadNotices(["https://privacyportalde-cdn.onetrust.com/f69c0bf1-10ab-4d33-8b59-e235ddd37a5f/privacy-notices/${_id}.json"])`);
    }

    render(){
        const langPreferance = this.props.lang;
        const _id = langPreferance==='fr-CA' ? fr_id : en_id;
        // const contestState = getContestState();
        return(
            <Fragment>
                <div id="main" className="main-wrapper no-bg">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                  {/* <h1>{ translate('footer.link4') }</h1> */}
                                  <div id={`otnotice-${_id}`} className="otnotice"></div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default Terms;