import React, { Component, Fragment } from 'react';
// import { OutboundLink } from 'react-ga';
import { NavLink } from 'react-router-dom';

//language file
import { translate } from '../../i18n/translate';

import MondelezLogo from '../../images/mondelez.svg';

import './footer.scss';

class Footer extends Component{

    render(){
        return(
            <Fragment>
                <footer>
                    <div className="site-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="footer-links">
                                        <li>
                                            <NavLink exact to="/rules">{translate('footer.link1')}</NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/privacy">{translate('footer.link2')}</NavLink>
                                        </li>
                                        <li>
                                            <a href={this.props.lang === "fr-CA" ? "https://www.mondelezinternational.com/canada-french/quebec-privacy-officer/" : "https://www.mondelezinternational.com/canada/quebec-privacy-officer/"}
                                                target="_blank" rel="noopener noreferrer">
                                                     {translate('footer.link3')}  
                                            </a>
                                        </li>
                                        <li>
                                            <NavLink exact to="/terms">{translate('footer.link4')}</NavLink>
                                        </li>
                                        <li>
                                            <a href={this.props.lang === "fr-CA" ? "https://www.mondelezinternational.com/-/media/Mondelez/Country/Canada/French/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf" : "https://www.mondelezinternational.com/-/media/Mondelez/Country/Canada/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf"}
                                                target="_blank" rel="noopener noreferrer">
                                                     {translate('footer.link5')}  
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div className="row mondelez-logo">
                                <div className="col-md-10 offset-md-1">
                                    <div className="mondelez-logo-container">
                                        <img src={MondelezLogo} alt="Mondelez Logo" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mondelez-logo">
                                <div className="col-md-10 offset-md-1">
                                    <div className="text-white small text-center">
                                        {translate('footer.sponsor',{br:<br/>})}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}

export default Footer;