import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';
import { LOCALES } from '../../i18n'; //language files

import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';
import packshot from '../../../src/images/packshot-podium.png';
import step2 from '../../../src/images/barcode.en.png';
import step2fr from '../../../src/images/barcode.fr.png';
// import step3 from '../../../src/images/prizes.png';

import forza from '../../images/forza-white.png';
import xbox from '../../images/xbox.png';

class Homepage extends Component {

    state = {
        isHover : false
    }

    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);

        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        gsap.from([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .1
        },.1)
    }

    componentWillUnmount(){
        
    }

    render(){
        const langPreferance = this.props.lang;
        return (
            <Fragment>
                <div className="main-wrapper">
                    <div className="container home">
                        <div className="row">
                            <div className="col home-text-container text-left"> {/*before it was col-md-6*/}
                                <div className="heading-text">
                                    <div>
                                        <h1 ref={el => {this.headingOne = el}}>
                                            <span>{translate('homepage.head1')}</span>
                                            {translate('homepage.head2',{b:e=><b>{e}</b>,sub:e=><sub>{e}</sub>,sup:e=><sup>{e}</sup>})}<spacer /><br/>
                                            {translate('homepage.head3')}
                                            <small>{translate('homepage.prizeSub')}</small>
                                        </h1>
                                    </div>
                                    <h2 ref={el => {this.headingTwo = el}}>
                                        {translate('homepage.pluscash',{span:e=><span>{e}</span>})}
                                        <small>{translate('homepage.cashSub')}</small>
                                    </h2>
                                    <div className="logos">
                                        <img src={forza} alt="Forza Motorsport" />
                                    </div>
                                </div>
                                <div className='home-body-text-wrap'>
                                    <NavLink exact className="enter-btn reverse" activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                        {translate('enterNow')}
                                    </NavLink>
                                </div>
                                <img src={xbox} alt="Custom Xbox" className="hero-box" />
                                
                                
                            </div>
                        </div>
                        <div className="learn-more-container">
                            <a href="#howtoenter">
                                {translate('homepage.cta')}
                            </a>
                        </div>

                        <div className="row" id="howtoenter">
                            <div className="col-md-12">
                                <h2>{translate('homepage.howtoenter')}</h2>
                            </div>
                            <div className="col-md-4">
                                <img src={packshot} className="packshot img-fluid overflow" alt="Mondelez packs" />
                                <h3>{translate('homepage.step1head')}</h3>
                                <p>{translate('homepage.step1')} <NavLink to="/products">{translate('popupBodylink')}</NavLink>{translate('homepage.step1post')}</p>
                            </div>
                            <div className="col-md-4">
                                <img src={langPreferance===LOCALES.ENGLISH?step2:step2fr} className="stepimage img-fluid" alt="UPC code with highlighted numbers" />
                                <h3>{translate('homepage.step2head')}</h3>
                                <p>{translate('homepage.step2')}</p>
                            </div>
                            <div className="col-md-4">
                                <img src={xbox} className="stepimage img-fluid" alt="Custom Forza Xbox Console" />
                                <h3>{translate('homepage.step3head')}</h3>
                                <p>{translate('homepage.step3')}</p>
                                
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <NavLink exact className="enter-btn" activeClassName="selectedLink" to='/enter-contest' tabIndex="0">
                                    {translate('enterNow')}
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-10 mx-auto disclaimer">
                                {translate('legal',{sup:e=><sup>{e}</sup>})}
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default Homepage;