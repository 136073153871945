import React, { Component, Fragment } from 'react';
// import ReactGA from 'react-ga';

//language file
// import { translate } from '../../i18n/translate';

// import { getContestState } from '../../util';
import '../../styles/pages/_rules.scss';

class CookiesPage extends Component{

    componentDidMount(){
        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render(){
        const langPreferance = this.props.lang;
        // const contestState = getContestState();
        return(
            <Fragment>
                <div id="main" className="main-wrapper no-bg">
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 offset-md-1">
                                {langPreferance === 'fr-CA' ? 
                                    <div class="cookie-page__content">
                                        <h2> POLITIQUE RELATIVE AUX COOKIES</h2>
                    <p> Un cookie est un petit fichier texte enregistré sur votre ordinateur ou votre appareil mobile lorsque vous visitez un site Web. Les cookies sont ensuite renvoyés au site Web d'origine lors de chaque visite ultérieure, ou à un autre site Web qui reconnaît ce cookie, afin de créer un enregistrement de votre activité en ligne. Les cookies sur ce site peuvent être:</p>
                    <ul class="list-marker">
               <li> supprimés automatiquement après chaque visite (cookies de session) ou maintenus en place lors de visites multiples (cookies persistants);</li>
                 <li> livrés dans un contexte propriétaire (défini par nous) ou de tiers (défini par un autre site Web).</li>
                    </ul>
                    <p> Sur ce site Web, nous et des tiers (identifiés comme partenaires) utilisons des cookies à différentes fins, notamment pour : faciliter votre navigation, personnaliser le contenu de la page, adapter la publicité à vos centres d'intérêts et mesurer l'utilisation du site. Plus précisément, nous utilisons les cookies suivants:</p>
{/* <!-- OneTrust Cookies List start --> */}
<div id="ot-sdk-cookie-policy"></div>
{/* <!-- OneTrust Cookies List end --> */}

                    <h2> LE COOKIES TIERS.</h2>
                    <p> Lorsque vous utilisez notre site Web, vous pouvez rencontrer du contenu intégré ou être dirigé vers d'autres sites Web pour certaines activités. Ces sites Web et les contenus intégrés peuvent utiliser leurs propres cookies. Nous ne contrôlons pas le placement de cookies par d'autres sites Web, même si vous y êtes dirigé depuis notre site Web.</p>
                    <p> Notre outil de préférences de cookies peut être utilisé pour personnaliser vos préférences de cookies. L'outil enregistrera votre consentement à notre politique relative aux cookies et vous le redemandera tous les 12 mois afin de vous tenir au courant des modifications apportées à notre politique relative aux cookies. Notre outil contrôle les cookies de performance, de fonctionnalité et pour une publicité ciblée que nous avons définis. Les cookies strictement nécessaires ne peuvent pas être désactivés, et l'outil ne peut être utilisé pour bloquer les cookies de sites tiers liés à notre site Web.</p>
                    <p> De nombreux cookies utilisés sur notre site Web peuvent également être activés ou désactivés via votre navigateur. Pour ce faire, suivez les instructions qui figurent généralement dans les menus «Aide», «Outils» ou «Éditer» de votre navigateur. Veuillez noter que la désactivation d'un cookie ou d'une catégorie de cookies ne supprime pas le cookie de votre navigateur, sauf si elle est manuellement complétée via la fonction de votre navigateur. </p>
					
		{/* <!-- OneTrust Cookies Settings button start --> */}
<button id="ot-sdk-btn" class="ot-sdk-show-settings"> Cookie Settings</button>
{/* <!-- OneTrust Cookies Settings button end --> */}
        </div>
                                :
                                    <div class="cookie-page__content">
                                    <h2> COOKIES NOTICE</h2>
                    <p> A cookie is a small text file that is saved on your computer or mobile device when you visit a website. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie, to develop a record of your online activity. Cookies on this site may be:</p>
                    <ul class="list-marker">
                        <li> automatically deleted after every visit (session cookies) or remaining in place during multiple visits (persistent cookies);</li>
                        <li> delivered in a first-party (set by us) or third-party (set by another website) context.</li>
                    </ul>
                    <p> On this website, we and third parties are using cookies for different purposes, including: facilitating your navigation, personalising content, tailoring advertising to your interests, and measuring site usage. Specifically, we use the following cookies:</p>
{/* <!-- OneTrust Cookies List start --> */}
<div id="ot-sdk-cookie-policy"></div>
{/* <!-- OneTrust Cookies List end --> */}

                    <h2> THIRD PARTY WEBSITES' COOKIES </h2>
                    <p> When using our website, you may encounter embedded content, or you may be directed to other websites for certain activities. These websites and embedded content may use their own cookies. We do not have control over the placement of cookies by other websites, even if you are directed to them from our website.</p>
                    <p> Our Cookie Preferences Tool can be used to customize your cookie preferences. The tool will record your consent to our cookie policy and will ask for it again every 12 months, to ensure you stay up-to-date with changes to our cookie policy. Our tool controls the Performance, Functional and Targeting cookies set by us. Strictly Necessary cookies cannot be disabled, nor can the tool be used to block cookies on third-party websites linked from our website.</p>
                    <p> Many of the cookies used on our website can be also enabled or disabled through your browser. To do so, follow the instructions usually located within the “Help,” “Tools” or “Edit” menus in your browser. Please note that disabling a cookie or category of cookies does not delete the cookie from your browser unless manually completed through your browser function.</p>

					
{/* <!-- OneTrust Cookies Settings button start --> */}
<button id="ot-sdk-btn" class="ot-sdk-show-settings"> Cookie Settings</button>
{/* <!-- OneTrust Cookies Settings button end --> */}
           </div>}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        )
    }
}

export default CookiesPage;