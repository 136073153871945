import React, { Component, Fragment } from 'react';
import { gsap, Power3 } from 'gsap';
// import { NavLink } from 'react-router-dom';
// import ReactGA from 'react-ga';

// import '../../styles/pages/_homepage.scss';

//import image
import { translate } from '../../i18n/translate';

import forza from '../../images/forza-white.png';
import xbox from '../../images/xbox.png';

class ContestClosed extends Component {

    state = {
        isHover : false
    }

    componentDidMount(){

        //initiate google analytics
        // ReactGA.pageview(window.location.pathname + window.location.search);


        //GSAP animation for the heading elements
        const { headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText } = this;
        gsap.from([headingOne, headingTwo, headingThree, headingFour, headingFive, bodyText, '.ckey'], .8, {
            opacity: 0,
            y: 40,
            ease: Power3.easeOut,
            stagger: .1
        },.1)
    }

    componentWillUnmount(){
        
    }

    render(){
        const langPreferance = this.props.lang;
        return (
            <Fragment>
                <div className="main-wrapper">
                    <div className="container home">
                        <div className="row">
                            <div className="col home-text-container text-left"> {/*before it was col-md-6*/}
                                <div className="heading-text">
                                    <div>
                                        <h1 ref={el => {this.headingOne = el}}>
                                            <span>{translate('homepage.head1')}</span>
                                            {translate('homepage.head2',{b:e=><b>{e}</b>,sub:e=><sub>{e}</sub>,sup:e=><sup>{e}</sup>})}<spacer /><br/>
                                            {translate('homepage.head3')}
                                            <small>{translate('homepage.prizeSub')}</small>
                                        </h1>
                                    </div>
                                    <h2 ref={el => {this.headingTwo = el}}>
                                        {translate('homepage.pluscash',{span:e=><span>{e}</span>})}
                                        <small>{translate('homepage.cashSub')}</small>
                                    </h2>
                                    <div className="logos">
                                        <img src={forza} alt="Forza Motorsport" />
                                    </div>
                                </div>
                                <div className='home-body-text-wrap'>
                                    <p className="home-body-text">{translate('contestClosedHead', {br: <br/>})}</p>
                                    <p className="home-body-text">{translate('contestClosedText', {br: <br/>})}</p> 
                                </div>
                                <img src={xbox} alt="Custom Xbox" className="hero-box" />
                                
                                
                            </div>
                        </div>
                    </div>
                    <div className="container disclaimer-wrap">
                        <div className="row">
                            <div className="col-md-10 mx-auto disclaimer">
                                {translate('legal',{sup:e=><sup>{e}</sup>})}
                            </div>
                        </div>
                    </div>
                </div>
                
            </Fragment>
        );
    }
}

export default ContestClosed;