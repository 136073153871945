import {LOCALES} from '../locales.js';

export default {
    [LOCALES.FRENCH]: {

        //global
        'site.title' : 'Recharger tes collations avec Xbox!',
        'close': 'Proche',

        //header

        //nav
        'menu.item.home' : 'ACCUEIL',
        // 'menu.item.winner' : 'ANCIENS GAGNANTS',
        'menu.item.1': 'PARTICIPEZ AU CONCOURS',
        'menu.item.2': 'DÉTAIL DES PRIX',
        'menu.item.3': 'FAQ',
        'menu.item.4': 'RÈGLEMENT',
        'menu.item.5': 'PRODUITS PARTICIPANTS',
        
        'contact-email': 'serviceclient@rechargetescollationavecxboxconcours.ca',

        //footer
        'footer.link1' : 'Règlement',
        'footer.link2' : 'Politique de confidentialité',
        'footer.link3' : 'Responsable de la protection de la vie privée, Québec',
        'footer.link4' : 'Conditions d’utilisation',
        'footer.link5' : 'Politiques sur l’accessibilitié',
        'footer.sponsor': 'Commanditaire : Mondelez Canada Inc., 277 Gladstone Avenue, Toronto, ON, M6J 3L9 ©Groupe Mondelez International. Les marques de commerce appartiennent à leur propriétaire respectif.{br}{br}Xbox est une marque déposée de Microsoft. Microsoft n’est pas un Commanditaire.',

        //homapge
        'homepage.head1': 'VOUS POURRIEZ',
        'homepage.head2' : '<b>GAGNER</b><sup>*</sup> 1 <sub>DES</sub> 10 CONSOLES ',
        'homepage.head3' : 'XBOX SERIES X PERSONNALISÉE',
        'homepage.prizeSub' : '(VAD DE 2 500 $)',
        'homepage.pluscash': '<span>Plus des cartes-cadeaux</span>XBOX INSTANTANÉES',
        'homepage.cashSub' : '(VAD DE 100 $)',
        'homepage.cta' : "Faire défiler la page pour savoir comment participer",
        'homepage.howtoenter' : 'COMMENT ÇA FONCTIONNE',
        'homepage.step1head' : 'ÉTAPE 1',
        'homepage.step1' : 'Achetez un ',
        'homepage.step1post' : ' Mondelez.',
        'homepage.step2head' : 'ÉTAPE 2',
        'homepage.step2' : 'Repérez le CUP de 11 chiffres** sur l’emballage du produit Mondelez participant. ***Omettez le premier chiffre à gauche du code.',
        'homepage.step3head' : 'ÉTAPE 3',
        'homepage.step3' : 'Entrez des CUP et courez la chance de gagner* 1 des 10 consoles Xbox Series X personnalisées plus des cartes-cadeaux Xbox instantanées.',

        'contestClosedHead' : 'CONCOURS TERMINÉ',
        'contestClosedText' : 'Merci pour votre participation!',
        // 'contestClosedLink' : 'https://howcaramilkismade.com/',
        // 'contestClosedCTA' : 'click here',

        'enterNow' : 'PARTICIPER',

        'legal' : "*AUCUN ACHAT REQUIS. Ouvert aux résidents du Canada ayant atteint l’âge de la majorité.   Le concours commence le 1<sup>er</sup> août 2024 à 00:00:00 HE et se termine le 15 novembre 2024 à 23:59:59 HE. Pour tous les détails sur la participation, les produits participants et le règlement complet, rendez-vous sur www.RechargetescollationsavecXbox.ca. À gagner : (i) 25 prix instantanés (VAD : 100 $CAN chacun) consistant en des cartes-cadeaux Xbox; (ii) 10 grands prix consistant en une console de jeux Xbox Series X personnalisée (VAD : 2 500 $CAN chacune).  Les chances de gagner dépendent du nombre de participations admissibles. Réponse à une question d’habileté requise. Les marques de commerce appartiennent à leur propriétaire respectif. Xbox est une marque déposée de Microsoft. Microsoft n’est pas un Commanditaire.",
        'legal.link' : 'www.RechargeTesCollationsAvecXbox.ca',

        //coming soon and home page
        'comingsoon.head1' : "Préparez-vous à",
        'comingsoon.head2' : "lancer les moteurs et à",
        'comingsoon.head3' : "recharger vos collations avec Xbox!",
        'comingsoon' : 'Ça s’en vient – 1<sup>er</sup>\u00A0août\u00A02024',

        //enter contest page
        'step1': 'Étape 1',
        'enterPin': 'ENTREZ 1 CUP',
        'enterKeyDescription': "Entrez le CUP de 11 chiffres** inscrit sur l’emballage du ",
        'enterKeyLink': "produit Mondelez participant",
        'enterKeySmall': '***Omettez le premier chiffre à gauche du code.',
        'popupBodytext' : 'Recherchez le code UPC au dos de votre achat d’un ',
        'popupBodylink' : 'produit participant',
        'pin': 'Code CUP',
        'nextStep': 'PROCHAINE ÉTAPE',

        'step2': 'Étape 2',
        'enterInfo': 'INFORMATION DU PARTICIPATION',
        'enterInfoDescription': 'Veuillez fournir les renseignements ci-dessous pour participer et savoir si vous avez gagné un prix instantané!',
        'firstName': 'Prénom',
        'lastName': 'Nom de famille',
        'emailAddress': 'Courriel',
        'phone': 'N° de téléphone',
        'required': '*Champs obligatoires',
        'infoForm.age': "Je confirme que j’ai atteint l’âge de la majorité dans la province/le territoire où je réside*",
        'infoForm.rules' : "J'ai lu le ",
            'infoForm.rules.link1' : 'Règlement du concours',
            'infoForm.rules2' : ' et la ',
            'infoForm.rules.link2' : 'Politique sur la protection des renseignements personnels de Mondelez',
            'infoForm.rules3' : '* et je confirme que je m’y conforme',
        'infoForm.optin' : "Je confirme que je consens à ce que Mondelez Canada Inc. stocke, communique et utilise les renseignements personnels que j’ai fournis pour l’administration du concours (y compris pour m’informer si je suis désigné(e) comme gagnant(e) admissible d’un prix) conformément au Règlement du concours et à la Politique sur la protection des renseignements personnels de Mondelez*",
        'requiredFields' : '*Champs obligatoires',
        'submit': 'ENVOYER',

        //winning page
        'congratulation' : 'VOUS Y ÊTES PRESQUE !',
        'winningPage.heading' : 'VOUS POURRIEZ GAGNER UNE CARTE-CADEAU XBOX DE 100 $!',
        'winningPage.body' : "Avant de pouvoir réclamer votre prix, vous devez répondre correctement et sans aide à la question de mathématique ci-dessous.",
        'winningPage.question' : '(6X6) – 16, puis ajouter 5 =',
        'winningPage.answer' : "Votre Réponse",
        'winningPage.cta' : 'SOUMETTRE',
        'winningPage.legal' : 'Please retain your PIN code /wrapper as the Contest Sponsor may require production of the winning PIN code before awarding a prize.',

        'winningPage.heading1' : 'FÉLICITATIONS !',
        'winningPage.heading2' : 'VOUS POURRIEZ GAGNER UNE CARTE-CADEAU XBOX DE 100 $!',
        'winningPage.body2' : "Notre équipe du service à la clientèle communiquera avec vous dans les 24 prochaines heures pour confirmer que vous êtes un(e) GAGNANT(E) officiel(le)!",
        'winningPage.body2a' : '.{br}{br}Veuillez ajouter l’adresse de courriel suivante aux adresses autorisées dans les paramètres de votre courriel : serviceclient@rechargetescollationsavecxboxconcours.ca{br}{br}De plus, votre nom a été inscrit au tirage du grand prix!',

        'winningPage.heading3' : 'DÉSOLÉ, MAUVAISE RÉPONSE.',
        'winningPage.body3' : 'Veuillez essayer à nouveau demain',

        // 'winningPage.heading2' : 'SHARE THE NEWS',
        'download': 'Télécharger',

        //losing page
        'tryAgain' : 'MERCI DE VOTRE PARTICIPATION!',
        'tryAgain.body' : "Votre inscription au prochain tirage est confirmée.{br}{br}Vous n’avez pas gagné de prix instantané, mais il y a d’autres cartes-cadeaux Xbox à gagner.br}{br}trouverez sur les emballages participants pour avoir une autre chance de gagner.",
        // 'tryAgain.bodyb' : ' pour une autre chance de gagner.',
        // 'tryAgain.btn' : 'RÉESSAYEZ', 
        'tryAgain.heading2' : 'PARTAGEZ LE PLAISIR',
        'tryAgain.body2' : 'Faites participer vos amis à l’action ! Partagez le concours avec vos amis et faites-leur savoir qu’il y a encore des prix XBOX à gagner ! ',
        'tryAgain.body3' : 'Vous voulez partager sur Instagram? Cliquez sur le bouton Télécharger pour recevoir une image que vous pourrez importer dans votre compte Instagram.',
        // 'popupBodylinkAlt' : 'emballages participants',

        //Prize Details
        'prizeDetails' : 'DÉTAIL DES PRIX',

        'prizeDetails-heading1' : 'LE GRAND PRIX',
        'prizeDetails-body1' : "Faites l’expérience de la Xbox Series X, la Xbox la plus rapide et la plus puissante. Compatible avec des milliers de titres de nos quatre générations de consoles, et en plus, le prix est assorti d’une version exclusive en série limitée de designs Forza Motorsport pour une sensation d’immersion encore plus intense dans la conduite.{br}{br}",
        'prizeDetails-body2a': '*Voir tous les détails dans le ',
        'prizeDetails-body2b': '*',
        'rulesLink': 'règlement du concours',
        

        'prizeDetails-heading2' : 'PRIX INSTANTANÉ',
        'prizeDetails-body2' : "Avec une carte-cadeau Xbox de 100 $, vous choisissez votre cadeau, que ce soit les nouveaux jeux Xbox, des apps, des fils ou séries télévisées, des appareils et plus encore. Aucuns frais d’activation ni de date d’expiration. Valide dans les boutiques Microsoft en ligne, sur Windows et sur Xbox.",

        

        // Participating Products
        'products' : 'PRODUITS PARTICIPANTS',
        
        'products.group2.title' : 'Crispers',
        'products.group2' : `CHR CRISPERS CHEDDAR 145G 12{br}
        CHRISTIE CRISPERS CHEDDAR 145 G 12{br}
        CRISPERS ALL DRESSED BULK 50G 84{br}
        CRISPERS ASSAISONNÉS VRAC 50 G 84{br}
        CRISPERS DILL PICKLE 145G 12{br}
        CRISPERS CORNICHONS À L'ANETH 145 G{br}
        CRISPERS BBQ 145G 12{br}
        CRISPERS BARBECUE 145 G 12{br}
        CRISPERS RANCH 145G 12{br}
        CRISPERS ALL DRESSED RRP 50G 3X14{br}
        CRISPERS ASSAISONNÉS 50 G 3X14{br}
        CRISPERS BITS & BITES ORIG 145G 12{br}
        CRISPERS MÉLI-MÉLO ORIGINAL 145G 12{br}
        CRISPERS BITS & BITES CHEESE 145G 12{br}
        CRISPERS MÉLI-MÉLO FROMAGE 145G 12{br}
        CRISPERS BITS & BITES BBQ 145G 12{br}
        CRISPERS MÉLI-MÉLO BBQ 145G 12{br}
        CRISPERS JALAPENO 145G 12{br}
        CRISPERS SMOKEY BACON 145G 12{br}
        CRISPERS BACON FUMÉ 145 G 12`,

        'products.group3.title' : 'Cadbury',
        'products.group3' : `CARAMILK 50G 6X48{br}
        CARAMILK 50G 6X48 GOLD{br}
        CARAMILK 50G 6X48 DOREE{br}
        CARAMILK 50G 6X48 GP{br}
        PAL FL CARAMILK SGL 50GR 8640CT{br}
        PAL PLEIN CARAMILK REG 50GR 8640UN{br}
        CARAMILK 50G 8X24{br}
        CARAMILK 50G 8X24 GOLD{br}
        CARAMILK 50G 8X24 DOREE{br}
        CARAMILK 50G 8X24 GP{br}
        CARAMILK 50G 6X48{br}
        WUNDERBAR 58GR 8X36{br}
        PPD CARAMILK SGL CTR 96CT{br}
        PREEMB COMPT CARAMILK REG 96UN{br}
        PPD CARAMILK SGL FLR 192CT{br}
        PREEMB PLANC CARAMILK REG 192UN{br}
        PPD CARAMILK SGL PDQ 270CT{br}
        PREEMB PDR CARAMILK REG 270UN{br}
        DRP HALF CARAMILK SGL 1728CT{br}
        DRP DEMI CARAMILK REG 1728UN{br}
        PPD CARAMILK SGL CTR 96CT{br}
        PREEMB COMPT CARAMILK REG 96UN{br}
        CRUNCHIE KING SIZE 66G 6X24{br}
        CRUNCHIE FORM SUPER 66G 6X24 32P{br}
        MR BIG ORIGINAL 4PK 240G 10{br}
        MR BIG ORIGINALE PQT DE 4 240 G 10{br}
        CARAMILK SLTD CARAMEL 50G 8X24{br}
        CARAMILK CARAMEL SALÉ 50G 8X24{br}
        CARAMILK SLTD CARAMEL 50G 8X24 GP{br}
        CARAMILK CARAMEL SALÉ 50G 8X24 GP{br}
        CRISPY CRUNCH 48G 12X24{br}
        WUNDERBAR 58G 12X24{br}
        WUNDERBAR 58G 12X24 XB{br}
        MR BIG ORIGINAL 60G 8X24{br}
        MR BIG ORIGINALE 60G 8X24{br}
        MR BIG ORIGINAL 60G 8X24{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINALE 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        MR BIG ORIGINALE 60G 8X24 XB{br}
        MR BIG ORIGINAL 60G 8X24 XB{br}
        PAL FL MR BIG 60G 4032CT{br}
        PAL PLEIN MR BIG EC 60GR 4032UN{br}
        WUNDERBAR CP 58GR 12X24{br}
        WUNDERBAR EC 58GR 12X24{br}
        CRUNCHIE CP 44GR 12X24{br}
        CRUNCHIE EC 44GR 12X24{br}
        CRUNCHIE 44G 12X24{br}
        MR BIG KING SIZE 90G 6X24{br}
        MR BIG FORMAT SUPER 90G 6X24{br}
        WUNDERBAR KING SIZE 90G 6X24{br}
        WUNDERBAR FORMAT SUPER 90G 6X24{br}
        CARAMILK KING SIZE 78G 6X24{br}
        CARAMILK FORMAT SUPER 78G 6X24{br}
        CARAMILK KING SIZE 78G 6X24 GOLD{br}
        CARAMILK FORMAT GÉANT 78 G 6X24{br}
        PPD MR BIG ORIGINAL CTR 96CT{br}
        PREEMB COMPT MR BIG ORIGINALE 96UN{br}
        PPD MR BIG ORIGINAL PDQ 132CT{br}
        PREEMB PDR MR BIG ORIGINALE 132 UN{br}
        PPD MR BIG DEAL CTR 96CT{br}
        PPD COMPT MR BIG DEAL 96UN{br}
        PPD MR BIG DEAL PDQ 132CT{br}
        PPD PDR MR BIG DEAL 132UN`,

        'products.group1.title' : 'Maynards/Sour Patch Kids',
        'products.group1' : `SOUR PATCH KIDS BIG HEADZ 154G 12{br}
        SOUR PATCH KIDS BERRIES 150G 12{br}
        SOUR PATCH KIDS BAIES 150 G 12{br}
        SPK WATERMELON 154G 12{br}
        SPK MELON D'EAU 154G 12{br}
        SOUR PATCH KIDS 150G 12{br}
        SOUR PATCH KIDS STRAWBERRY 154G 12{br}
        SOUR PATCH KIDS FRAISE 154 G 12{br}
        SOUR PATCH KIDS FUN MIX 315G 12{br}
        SPK RÉGALS-ÉCLAIR 315G 12{br}
        SPK STRAWBERRY 154G 2X9{br}
        SPK FRAISE 154G 2X9{br}
        SPK SR CHRY BLASTERS 154G 8{br}
        SOUR PATCH KIDS BIG HEADZ 154G 8{br}
        SOUR PATCH KIDS BERRIES 150G 8{br}
        SOUR PATCH KIDS BAIES 150 G 8{br}
        SPK WATERMELON 154G 8{br}
        SPK MELON D'EAU 154G 8{br}
        SOUR PATCH KIDS 150G 8{br}
        SOUR PATCH KIDS STRAWBERRY 154G 8{br}
        SOUR PATCH KIDS FRAISE 154 G 8{br}
        MAYNARDS FUZZY PEACH 154G 8{br}
        MAYNARDS WINE GUMS 154G 8{br}
        MAYNARDS SWEDISH BERRIES 154G 8{br}
        MAYNARDS SWEDISH BERRIES 154G 8{br}
        MAYNARDS SWEDBRS & CREME 154G 8{br}
        MAYNARDS SWEDBRS & CREME 154G 8{br}
        SOUR PATCH KIDS GRAPE 154G 8{br}
        SOUR PATCH KIDS RAISIN 154G 8{br}
        SOUR PATCH KIDS GRAPE 154G 8{br}
        SOUR PATCH KIDS RAISIN 154G 8{br}
        MAYNARDS ORIGINAL GUMMIES 150G 8{br}
        SPK SR CHRY BLASTERS 154G 12{br}
        MAYNARDS SWEDBRS & CREME 154G 12{br}
        MAYNARDS SWEDBRS & CREME 154G 12{br}
        MAYNARDS WINE GUMS 154G 12{br}
        MAYNARDS FUZZY PEACH 154G 12{br}
        SOUR PATCH KIDS GRAPE 154G 12{br}
        SOUR PATCH KIDS RAISIN 154G 12{br}
        SOUR PATCH KIDS GRAPE 154G 12{br}
        SOUR PATCH KIDS RAISIN 154G 12{br}
        MAYNARDS ORIGINAL GUMMIES 150G 12{br}
        SOUR PATCH KIDS 315G 12{br}
        SPK WATERMELON 315G 12{br}
        SPK MELON D'EAU 315G 12{br}
        SPK SRCHRY BLASTERS 308G 12{br}
        MAYNARDS SWEDBRY TROPICAL 315G 12{br}
        MAYNARDS SWEDISH BERRIES 315G 12{br}
        MAYNARDS FUZZY PEACH 308G 12{br}
        MAYNARDS SWEDISH BERRIES 154G 12{br}
        SOUR PATCH KIDS LEMONADE 150G 12{br}
        SOUR PATCH KIDS LIMONADE 150 G 12{br}
        SOUR PATCH KIDS LEMONADE 150G 8{br}
        SOUR PATCH KIDS LIMONADE 150G 8{br}
        SOUR PATCH KIDS BLUE 150G 12{br}
        SOUR PATCH KIDS BLEU 150 G 12{br}
        MAYNARDS SOUR PATCH KIDS 185G 12{br}
        MAYNARDS SOUR PATCH KIDS 185G 12 XB{br}
        MAYNARDS WINE GUM ROLLS 44G 12X18{br}
        MAYNARDS WINE GUMS 315G 12{br}
        MAYNRD SPK SRCHRY BLASTER 64G 12X18{br}
        MAYNARDS FUZZY PEACH 64G 12X18{br}
        MAYNARDS SWEDISH BERRIES 64G 12X18{br}
        MAYNARDS ORIG GUMMIES 60G 12X18{br}
        MAYNARDS SOUR PATCH KIDS 60G 12X18{br}
        MAYNARDS SOUR PATCH KIDS 185G 2X9{br}
        MAYNARDS SOUR PATCH KIDS 185G 2X9 XB{br}
        MAYNARDS FUZZY PEACH BOX 100G 12{br}
        MAYNARDS FUZZY PEACH BOITE 100G 12{br}
        MAYNRD SOUR WATERMELON BOX 100G 12{br}
        MAYNRD SOUR WATERMELON BTE 100G 12{br}
        MAYNRD SWEDISH BERRIES BOX 100G 12{br}
        MAYNRD SWEDISH BERRIES BTE 100G 12{br}
        MAYNARDS SPK EXTREME BOX 100G 12{br}
        MAYNARDS SPK EXTREME BOITE 100G 12{br}
        MAYNARDS WINE GUMS DRC 315G 10{br}
        MAYNRD WINE GUMS BTE PRES 315G 10{br}
        SOUR PATCH KIDS EXTREME 150G 12`,

        //faq page
        'faq-heading-text1': 'Vous trouverez le détail des modalités de la promotion dans ',
            'faq-heading-link': 'le Règlement',
        'faq-heading-text2': '. Vous trouverez les réponses aux questions courantes ci-dessous.',

        'q1': 'Q1. Quelles sont les dates de cette promotion?',
        'a1': 'R. Le concours commence le 1 er août et se termine le 15 novembre 2024.',
        
        'q2': 'Q2. Quels sont les prix offerts?',
        'a2': "R.  Prix disponibles au début du concours :{br}{br}Dix (10) grands prix seront attribués. Chaque grand prix consiste en une console de jeux Xbox Series X (VAD : 2 500 $CAN chacune).{br}{br}De plus, vingt-cinq (25) prix instantanés consistant en cartes-cadeaux Xbox seront attribués (VAD : 100 $CAN chacune).",
        
        'q2-b' : 'Q3. Comment puis-je participer sans acheter de produit?',
        'a2-b' : 'R. Pour obtenir un CUP sans achat d’un produit admissible, envoyez un courriel à',
        'a2-c' : 'pendant la période de la promotion. Le courriel doit comprendre les informations suivantes : (i) ligne Objet : <b>Recharge tes collations avec Xbox – Demande de participation sans achat</b>; (ii) prénom, nom de famille, numéro de téléphone et âge; (iii) une phrase unique et originale d’au moins cinquante (50) mots sur votre <b>véhicule Forza Motor Sport préféré et pourquoi c’est votre préféré</b> (les 2 sont nécessaires). À la réception de l’information demandée conformément au Règlement, l’administrateur vous enverra par courriel un CUP sélectionné au hasard.',
        
        'q3': 'Q4. Combien de fois puis-je participer?',
        'a3': "R. Le nombre de participations est limité à une (1) par personne, par jour.",
        
        'q4': 'Q5. Combien de prix pourrais-je gagner?',
        'a4': "R. Il y a une limite d’un (1) grand prix par personne et d’un (1) PRIX INSTANTANÉ par personne pendant la durée de la promotion.",

        'q5': "Q6. J’ai un CUP valide, mais je reçois un message d’erreur.",
        'a5': "R. Veuillez saisir le(s) code(s) sans tirets ni espaces; consultez notre liste de ",
        'a5a': 'produits participants',
        'a5b': ' pour vous assurer que votre achat est admissible. Si le code ne fonctionne toujours pas, nous sommes là pour vous aider!{br}{br}Veuillez nous contacter au ',

        'q6': "Q7. J’ai reçu un message m’indiquant que j’avais déjà participé aujourd’hui, mais ce n’est pas le cas. Que dois-je faire?",
        'a6': 'R. Tapez l’adresse ',
            'a6-link': 'https://rechargetescollationsavecxbox.ca/',
            'a6-bottom': " directement dans votre navigateur plutôt que d’ouvrir une page mise en signet. Essayez également d’effacer les cookies et le cache de votre navigateur Internet.",

        'q7': "Q8. J’éprouve des problèmes techniques. Que dois-je faire?",
        'a7': "R. Parfois, il s’agit simplement de faire une mise à jour de votre navigateur. Assurez-vous que vous disposez d’une version récente de Chrome, Internet Explorer, Firefox ou Safari. Vous pouvez également aller dans les préférences de votre navigateur pour vous assurer que JavaScript est activé. La suppression du cache et des cookies peut également s’avérer utile dans ce cas.",

        'q8': "Q9. Comment saurai-je si je suis un(e) gagnant(e) potentiel(le) d’un prix INSTANTANÉ?",
        'a8': "Le cas échéant, un message s’affichera à l’écran. Notre équipe du service à la clientèle communiquera alors directement avec vous par courriel (serviceclient@rechargetescollationavecxboxconcours.ca) et vous devrez remplir le formulaire de déclaration et d’exonération, et répondre correctement à une question d’habileté mathématique avant de pouvoir réclamer votre prix.",

        // 'q9': "Q10. J'ai récemment mis à jour mon adresse électronique. Puis-je modifier mes informations de participation à cette promotion?        ",
        // 'a9': "R. Nous nous excusons pour ce désagrément, mais en raison du nombre de participations que nous recevons, nous ne sommes pas en mesure de modifier les informations relatives à la participation. Si vous participez au tirage au sort du grand prix pendant la période promotionnelle et que vos coordonnées changent, contactez serviceclient@rechargetescollationavecxboxconcours.ca et fournissez vos coordonnées mises à jour. Remarque : si vos coordonnées changent pendant la période de promotion, nous ne pouvons pas garantir que nous pourrons répondre à votre demande. Vous avez encore une question ? N'hésitez pas à nous contacter à  serviceclient@rechargetescollationavecxboxconcours.ca. Veuillez noter qu'une réponse peut prendre jusqu'à trois (3) jours ouvrables. ",

        // 'q10': 'Q. J’ai des difficultés à faire fonctionner mon PC Game Pass ? Que devrais-je faire ?',
        // 'a10': 'R. Pour plus de détails sur la façon d’échanger un code, visitez ',
        // 'a10-link': 'https://support.xbox.com/fr-CA/help/subscriptions-billing/redeem-codes-gifting/redeem-prepaid-codes',

        'q11': "Q10. J’ai récemment mis à jour mon adresse électronique. Puis-je modifier mes informations de participation à cette promotion?",
        'a11': 'R. Malheureusement, en raison du nombre de participations que nous recevons, nous ne sommes pas en mesure de modifier les informations relatives à la participation. Nous sommes désolés.{br}{br}Si vous participez au tirage au sort du Grand prix pendant la période de la promotion et que vos coordonnées changent, contactez ',
        'a11-2' : ' et fournissez vos coordonnées à jour.{br}{br}Remarque : si vos coordonnées changent pendant la période de la promotion, nous ne pouvons pas garantir que nous pourrons répondre à votre demande.{br}{br}Vous avez d’autres questions? N’hésitez pas à nous contacter à ',
        'a11-3' : ". Veuillez prévoir jusqu’à trois (3) jours ouvrables pour recevoir une réponse.",

        //Rules page
        'rules-page-heading': 'RÈGLEMENT',
    }
}